<template>
    <div class="project content">
        <main>
            <section class="project">
                <div class="title-info">
                    <div>
                        <h2>
                            Project <br />
                            {{ project.title }}
                        </h2>
                        <div class="info">
                            <h3 class="hidden">Project info</h3>
                            <ul>
                                <li>
                                    {{ project.location }}
                                </li>
                                <li v-if="project.designer">
                                    {{ $t("portfolio.project.design") }}
                                    <em>
                                        {{ project.designer }}
                                    </em>
                                </li>
                                <li v-if="project.renderer">
                                    {{ $t("portfolio.project.render") }}
                                    <em>
                                        {{ project.renderer }}
                                    </em>
                                </li>
                                <li>
                                    {{
                                        `${project.date.substring(
                                            8,
                                            10
                                        )}/${project.date.substring(
                                            5,
                                            7
                                        )}/${project.date.substring(0, 4)}`
                                    }}{{
                                        project.ongoing
                                            ? ` (${$t(
                                                  "portfolio.project.ongoing"
                                              )})`
                                            : ""
                                    }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="container back-link">
                    <Link
                        :back="true"
                        path="/portfolio"
                        :title="$t('nav.return')"
                    />
                </div>
                <div class="images">
                    <h3 class="hidden">Project images</h3>
                    <div class="grid" id="masonry">
                        <div
                            :class="{
                                'img-container': true,
                                landscape: (ind + 1) % 3 == 0
                            }"
                            v-for="(image, ind) in project.images.split(',')"
                            :key="ind"
                        >
                            <img
                                class="open-tinybox"
                                @click="index = ind"
                                v-if="project.images.split(',').length > 0"
                                :src="
                                    `${$apiroute}/images/${project.id}-${project.key}/${image}`
                                "
                                :title="`Project ${project.title} image`"
                                :alt="`Image for project ${project.title}`"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Tinybox
                class="white"
                v-if="project.images.length > 0"
                v-model="index"
                :images="images"
                loop
                no-thumbs
            />
            <div
                class="project-links container bottom"
                v-if="nextProject !== project"
            >
                <Link
                    :back="true"
                    :path="`/portfolio/${prevProject.id}/${prevProject.key}`"
                    :title="$t('portfolio.project.prev-project')"
                />
                <Link
                    :path="`/portfolio/${nextProject.id}/${nextProject.key}`"
                    :title="$t('portfolio.project.next-project')"
                />
            </div>
        </main>
        <Footer v-if="$route.name !== '404'" />
    </div>
</template>

<script>
import Link from "@/components/Link";
import Tinybox from "vue-tinybox";
import Footer from "@/components/layout/Footer";

export default {
    components: {
        Link,
        Tinybox,
        Footer
    },
    computed: {
        prevProject() {
            if (this.$store.getters.allProjects) {
                return this.$store.getters.getPrevProject(
                    this.$route.params.id
                );
            }
            return null;
        },
        nextProject() {
            if (this.$store.getters.allProjects) {
                return this.$store.getters.getNextProject(
                    this.$route.params.id
                );
            }
            return null;
        },
        project() {
            this.$emit("done");
            if (this.$store.getters.allProjects) {
                return this.$store.getters.getProjectById(
                    this.$route.params.id
                );
            }
            return null;
        },
        images() {
            if (!this.project) {
                return null;
            }
            let images = this.project.images.split(",");
            return images.map(
                image =>
                    `${this.$apiroute}/images/${this.project.id}-${this.project.key}/${image}`
            );
        }
    },
    mounted() {
        if (!this.project) {
            this.$emit("loading");
            this.$store.dispatch("getProjects");
        }
    },
    data: function() {
        return {
            index: null
        };
    },
    metaInfo() {
        return {
            title: `${this.project.title}`,
            htmlAttrs: {
                lang: this.$root.$i18n.locale
            },
            meta: [
                // Primary Meta Tags
                {
                    name: "title",
                    content: "Project - Renders by Julie"
                },
                {
                    name: "description",
                    content:
                        "Project gemaakt door Renders by Julie. Bekijk de renders van dit project in ons portfolio!"
                },
                // Secondary Meta Tags
                {
                    name: "author",
                    content: "Julie Lejour"
                },
                {
                    name: "keywords",
                    content: `${this.project.title}, portfolio, project, projecten, interieur, interior, exterieur, exterior, floorplan, vloerplan, render, renders, rendus, visual, visuals, visualisaties, model, beeld, beelden, 3D, 2D, ruimte, ruimtes, space, spaces sales plans, plans, plannen, verkoopsplannen, verkoopplannen, vloerplannen, grondplannen, plattegronden, floorplans, plans de vente, schema, opmaken, maken, visualiseren, ontwerpen, modelleren, visualize, visueel, 3D foto’s, 3D images, Julie, Lejour, Julie Lejour, Renders, Renders by Julie, rendersbyjulie, rendersbyjulie.be, portfolio, dwg, panorama, architecture, architectuur, Gent, Sint-Martens-Latem, De Pinte, Deinze, Merelbeke, Drongen, Oost-Vlaanderen`
                },
                {
                    name: "robots",
                    content: "index, follow"
                },
                {
                    "http-equiv": "Content-Type",
                    content: "text/html; charset=utf-8"
                },
                {
                    name: "language",
                    content: this.$root.$i18n.locale === "en" ? "EN" : "NL"
                },
                {
                    name: "revisit-after",
                    content: "30 days"
                },
                {
                    name: "publisher",
                    content: "Renders by Julie"
                },
                {
                    name: "copyright",
                    content: `Copyright ${new Date().getFullYear()} Renders by Julie - All Rights Reserved`
                },
                // Open Graph / Facebook
                { property: "og:locale", content: this.$root.$i18n.locale },
                { property: "og:type", content: "website" },
                // {
                //     property: "og:url",
                //     content: "https://rendersbyjulie.be/portfolio"
                // },
                {
                    property: "og:title",
                    content: "Project - Renders by Julie"
                },
                {
                    property: "og:description",
                    content:
                        "Project gemaakt door Renders by Julie. Bekijk de renders en visuals van dit project in ons portfolio!"
                },
                {
                    property: "og:image",
                    content: `${this.$apiroute}/images/${this.project.id}-${this.project.key}/${this.project.thumbnail}`
                },
                // Twitter
                { name: "twitter:card", content: "summary_large_image" },
                // {
                //     name: "twitter:url",
                //     content: "https://rendersbyjulie.be/portfolio"
                // },
                {
                    name: "twitter:title",
                    content: "Project - Renders by Julie"
                },
                {
                    name: "twitter:description",
                    content:
                        "Project gemaakt door Renders by Julie. Bekijk de renders en visuals van dit project in ons portfolio!"
                },
                {
                    name: "twitter:image",
                    content: `${this.$apiroute}/images/${this.project.id}-${this.project.key}/${this.project.thumbnail}`
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
main {
    position: relative;

    @media (max-width: 700px) {
        section {
            padding-top: 0;
        }
    }
}

.project {
    .back-link {
        margin-bottom: 1rem;
    }

    .title-info {
        min-height: 17rem;
        margin-bottom: 3rem;

        h2 {
            margin-bottom: 1rem;
            font-family: "Nelphim", serif;
            font-weight: normal;
        }

        & > div {
            position: absolute;
            right: 0;
        }

        @media (max-width: 700px) {
            margin-bottom: 5rem;

            & > div {
                left: 0;
            }
        }
    }

    .info {
        font-size: 18px;
        font-family: "Titillium Web", sans-serif;
        font-weight: normal;
        color: $dark-grey;
        transition: all 0.2s ease-out;

        li {
            margin-bottom: 0.5rem;
        }
    }

    .img-container {
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-bottom: 1.5rem;
        transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);

        &:hover {
            img {
                transform: scale(1.05);
            }
        }

        img {
            width: 100%;
            max-width: 100%;
            transform: translate(0, 0);
            cursor: pointer;
            transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
        }

        @media (max-width: 900px) {
            img {
                max-width: 100%;
            }
        }
    }

    .tinybox.white {
        background-color: rgba(255, 255, 255, 0.9);
    }

    .bottom {
        margin: 5rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 700px) {
            margin-top: 3.5rem;
        }
    }

    #masonry {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

        @media (max-width: 700px) {
            gap: 1rem;
        }

        img {
            max-width: 100%;
            display: block;
            object-fit: cover;
            // object-position: 0% 0%;
            height: 100%;
        }

        .img-container {
            margin: 0;
            display: grid;
            grid-template-rows: 1fr auto;
            min-height: 20rem;

            &:not(.landscape) {
                max-height: 30rem;
            }
        }

        .img-container > img {
            grid-row: 1 / -1;
            grid-column: 1;
        }

        .landscape {
            grid-column-end: span 2;
            max-height: 50rem;
        }
    }

    @media (max-width: 700px) {
        margin-bottom: 5rem;
    }
}
</style>
